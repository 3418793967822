"use client";

import React, { createContext, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Dialog, Navbar, NavbarHeading, OverlaysProvider, Icon as BlueprintIcon } from "@blueprintjs/core";

import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import {
  SidePanel,
  DEFAULT_SECTIONS,
  TemplatesSection,
  TextSection,
  PhotosSection,
  ElementsSection,
  // UploadSection,
  BackgroundSection,
  PagesSection,
  LayersSection,
  SizeSection,
  VideosSection,
  SectionTab,
} from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";
import { Tooltip } from "polotno/canvas/tooltip";
import { PagesTimeline } from "polotno/pages-timeline";
import { setTranslations, unstable_useHtmlTextRender } from "polotno/config";
import { unstable_registerToolbarComponent } from "polotno/config";
import { MOBILE_BREAKPOINT } from "polotno/utils/screen";

import { loadFile } from "./file";

import { QrSection } from "./sections/qr-section";
import { QuotesSection } from "./sections/quotes-section";
import { IconsSection } from "./sections/icons-section";
import { ShapesSection } from "./sections/shapes-section";
import { StableDiffusionSection } from "./sections/stable-diffusion-section";
import { MyDesignsSection } from "./sections/my-designs-section";

import { AIWriteMenu } from "./ai-text";
// import { VideosSection } from './sections/video-section';
// import { UploadSection } from './sections/upload-section';

import { useProject } from "./project";

import { ImageRemoveBackground } from "./background-remover";

import Topbar from "./topbar/topbar";
import { type StoreType } from "polotno/model/store";
import { AIMagicMenu, AIMagicTextTypesMenu } from "./ai-magic";
import { UploadSection } from "./sections/upload-section";
import { LegacyTemplatesSection } from "./sections/legacy-templates";
import { BasicModeSection } from "./sections/basic-mode";
import { ActionControls } from "./topbar/action-controls";
import { MainSection } from "./sections/main-section";
import {
  BackwardItem,
  Camera,
  DocumentUpload,
  Icon,
  Layer,
  Maximize3,
  PictureFrame,
  ScanBarcode,
  Shapes,
  TextBlock,
} from "iconsax-react";
import { createPortal } from "react-dom";

unstable_useHtmlTextRender(true);

const sections = [
  MainSection,
  MyDesignsSection,
  // {
  //   ...TemplatesSection,
  //   Tab: (props) => (
  //     <SectionTab name="Templates" {...props}>
  //       <BackwardItem variant="Linear" className="inline" />
  //     </SectionTab>
  //   ),
  // },
  LegacyTemplatesSection,
  {
    ...TextSection,
    Tab: (props) => (
      <SectionTab name="Text" {...props}>
        <TextBlock variant="Linear" className="inline" />
      </SectionTab>
    ),
  },
  {
    ...ShapesSection,
    Tab: (props) => (
      <SectionTab name="Shapes" {...props}>
        <Shapes variant="Linear" className="inline" />
      </SectionTab>
    ),
  },
  {
    ...IconsSection,
    Tab: (props) => (
      <SectionTab name="Icons" {...props}>
        <Icon variant="Linear" className="inline" />
      </SectionTab>
    ),
  },
  StableDiffusionSection,
  {
    ...PhotosSection,
    Tab: (props) => (
      <SectionTab name="Photos" {...props}>
        <Camera variant="Linear" className="inline" />
      </SectionTab>
    ),
  },
  {
    ...UploadSection,
    Tab: (props) => (
      <SectionTab name="Upload" {...props}>
        <DocumentUpload variant="Linear" className="inline" />
      </SectionTab>
    ),
  },
  // ElementsSection,
  {
    ...BackgroundSection,
    Tab: (props) => (
      <SectionTab name="Backgrounds" {...props}>
        <PictureFrame variant="Linear" className="inline" />
      </SectionTab>
    ),
  },
  // PagesSection,
  {
    ...LayersSection,
    Tab: (props) => (
      <SectionTab name="Layers" {...props}>
        <Layer variant="Linear" className="inline" />
      </SectionTab>
    ),
  },
  {
    ...SizeSection,
    Tab: (props) => (
      <SectionTab name="Size" {...props}>
        <Maximize3 variant="Linear" className="inline" />
      </SectionTab>
    ),
  },
  // VideosSection,
  // QuotesSection,
  QrSection,
];

/*
// replace elements section with just shapes
DEFAULT_SECTIONS.splice(3, 1, ShapesSection);
// add icons
DEFAULT_SECTIONS.splice(3, 0, IconsSection);
// add two more sections
DEFAULT_SECTIONS.push(QuotesSection, QrSection);
// DEFAULT_SECTIONS.unshift(UploadSection);
DEFAULT_SECTIONS.unshift(MyDesignsSection);

DEFAULT_SECTIONS.push(StableDiffusionSection);
// DEFAULT_SECTIONS.push(VideosSection);
*/

const isStandalone = () => {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone
  );
};

const getOffsetHeight = () => {
  let safeAreaInsetBottom = 0;

  if (isStandalone()) {
    // Try to get the safe area inset using env() variables
    const safeAreaInsetBottomString = getComputedStyle(
      document.documentElement
    ).getPropertyValue("env(safe-area-inset-bottom)");
    if (safeAreaInsetBottomString) {
      safeAreaInsetBottom = parseFloat(safeAreaInsetBottomString);
    }

    // Fallback values for specific devices if env() is not supported
    if (!safeAreaInsetBottom) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/iPhone|iPad|iPod/i.test(userAgent) && !window.MSStream) {
        // This is an approximation; you might need to adjust this value based on testing
        safeAreaInsetBottom = 20; // Example fallback value for iPhone
      }
    }
  }

  return window.innerHeight - safeAreaInsetBottom;
};

const useHeightAndWidth = () => {
  const [size, setSize] = React.useState([getOffsetHeight(), window.innerWidth] as const);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setSize([getOffsetHeight(), window.innerWidth]);
    });
  }, []);
  return size;
};

const EditorApp = observer(({ store }: { store: StoreType }) => {
  const project = useProject();
  const [height, width] = useHeightAndWidth();

  const [overrideMobile, setOverrideMobile] = React.useState(false);

  const toolbarContainerRef = useRef<HTMLDivElement>(null);

  const actualToolbar =
    toolbarContainerRef.current?.firstElementChild?.firstElementChild ?? null;

  const handleDrop = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    // skip the case if we dropped DOM element from side panel
    // in that case Safari will have more data in "items"
    if (ev.dataTransfer.files.length !== ev.dataTransfer.items.length) {
      return;
    }
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      loadFile(ev.dataTransfer.files[i], store);
    }
  };

  React.useEffect(() => {
    store.selectElements([]);
    if (project.basicMode) {
      store.openSidePanel(BasicModeSection.name);
      project.setRemixSideBarOpen(false);
      document.body.classList.add("basic-mode");
    } else {
      store.openSidePanel(LegacyTemplatesSection.name);
      project.setRemixSideBarOpen(true);
      document.body.classList.remove("basic-mode");
    }
  }, [project.basicMode]);

  const nullComponent = () => null;

  return (
    <div
      style={{
        width: "100vw",
        height: height + "px",
        display: "flex",
        flexDirection: "column",
      }}
      onDrop={handleDrop}
    >
      <Dialog
        isOpen={!overrideMobile && width < MOBILE_BREAKPOINT}
        onClose={() => setOverrideMobile(true)}
        canOutsideClickClose={false}
        className="w-[unset] p-8 relative force-overlay-top"
      >
        <BlueprintIcon
          icon="cross"
          size={24}
          role="button"
          aria-label="Close"
          className="absolute w-6 h-6 top-4 right-4 cursor-pointer"
          onClick={() => setOverrideMobile(true)}
        />
        <div className="flex max-h-full w-full max-w-[32rem]">
          <div className="flex flex-col gap-3 text-center sm:text-left">
            <h2 className="pb-2 text-xl font-semibold leading-none tracking-tight text-primary text-center">
              Welcome to the TeachShare Creator!
            </h2>
            <p className="text-md text-center">
              We're excited you're here! The TeachShare Creator works best on a
              desktop or laptop computer. Head to{" "}
              <a href="https://teachshare.com/creator">
              https://teachshare.com/creator
              </a>{" "}
              on your laptop or desktop and experience the full power of the
              TeachShare Creator!
            </p>
            <p className="text-md text-center">
              (Stay tuned, mobile support is
              coming soon!)
            </p>
          </div>
        </div>
      </Dialog>
      {actualToolbar &&
        createPortal(
          <>
            <Navbar.Group align={"left"} className="justify-center order-1">
              <NavbarHeading
                style={{
                  paddingLeft: "8px",
                  color: "#485AEE",
                  fontSize: 24, //18,
                  fontFamily: "Outfit",
                  fontWeight: "600",
                  letterSpacing: 0.18,
                  wordWrap: "break-word",
                }}
              >
                TeachShare
              </NavbarHeading>
            </Navbar.Group>
            <div className="bp5-navbar-group flex-1 order-2"></div>
          </>,
          actualToolbar
        )}
        <OverlaysProvider>
          <PolotnoContainer className="polotno-app-container">
            <SidePanelWrap>
              <SidePanel
                store={store}
                sections={project.basicMode ? [BasicModeSection] : sections}
                defaultSection={project.basicMode ? BasicModeSection.name : LegacyTemplatesSection.name}
              />
            </SidePanelWrap>
            <WorkspaceWrap>
              <div
                className="polotno-editor-bar-container"
                ref={toolbarContainerRef}
              >
                <Toolbar
                  store={store}
                  components={{
                    ...(project.basicMode ? {
                        TextFontFamily: nullComponent,
                        TextFontSize: nullComponent,
                        TextFontVariant: nullComponent,
                        TextFilters: nullComponent,
                        TextFill: nullComponent,
                        TextSpacing: nullComponent,
                        TextAnimations: nullComponent,
                        TextAiWrite: nullComponent,

                        ImageFlip: nullComponent,
                        ImageFilters: nullComponent,
                        ImageFitToBackground: nullComponent,
                        ImageCrop: nullComponent,
                        ImageClip: nullComponent,
                        ImageRemoveBackground: nullComponent,
                        ImageAnimations: nullComponent,

                        SvgFlip: nullComponent,
                        SvgFilters: nullComponent,
                        SvgColors: nullComponent,
                        SvgAnimations: nullComponent,

                        LineSettings: nullComponent,
                        LineColor: nullComponent,
                        LineHeads: nullComponent,
                        LineAnimations: nullComponent,

                        FigureFill: nullComponent,
                        FigureStroke: nullComponent,
                        FigureSettings: nullComponent,
                        FigureFilters: nullComponent,
                        FigureAnimations: nullComponent,

                        VideoTrim: nullComponent,
                        VideoAnimations: nullComponent,

                        ManyAnimations: nullComponent,

                        PageDuration: nullComponent,

                        // History: nullComponent,
                        Group: nullComponent,
                        Position: nullComponent,
                        Opacity: nullComponent,
                        Lock: nullComponent,
                        Duplicate: nullComponent,
                        Remove: nullComponent,
                    } : {
                        ImageRemoveBackground,
                        // TextAIWrite: AIWriteMenu,
                        TextAiMagicMenu: AIMagicMenu,
                        ImageAiMagicMenu: AIMagicMenu,
                        ManyAiMagicMenu: AIMagicMenu,
                        TextAIMagicTextTypesMenu: AIMagicTextTypesMenu,
                        ImageAIMagicTextTypesMenu: AIMagicTextTypesMenu,
                        ManyAIMagicTextTypesMenu: AIMagicTextTypesMenu,
                        // FIXME: Group[...] doesn't seem to work
                        // GroupAIMagicTextTypesMenu: AIMagicTextTypesMenu,
                    }),
                    ActionControls,
                  }}
                />
              </div>
              <Workspace
                store={store}
                components={{
                  Tooltip,
                  TextAIWrite: AIWriteMenu,
                  ...(project.basicMode ? {
                    PageControls: nullComponent,
                  } : {}),
                }}
                pageBorderColor="transparent"
                activePageBorderColor="#6271EB"
                paddingX={10}
                paddingY={10}
              />
              <ZoomButtons store={store} />
              <PagesTimeline store={store} />
            </WorkspaceWrap>
          </PolotnoContainer>
        </OverlaysProvider>
    </div>
  );
});

unstable_registerToolbarComponent(
  "group",
  observer(({ store }) => (
    <Navbar.Group align="left">
      <AIMagicTextTypesMenu store={store} />
    </Navbar.Group>
  ))
);

export default EditorApp;

"use client";

import { Ref, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  InputGroup,
  Button,
  TextArea,
  FileInput,
  Divider,
  FormGroup,
  MenuItem,
  Switch,
  NumericInput,
} from "@blueprintjs/core";
import { Tab, Tabs } from "@blueprintjs/core";
import { Clean, Divide } from "@blueprintjs/icons";

import { SectionTab } from "polotno/side-panel";
import { getKey } from "polotno/utils/validate-key";
import { getImageSize } from "polotno/utils/image";
import { t } from "polotno/utils/l10n";

import { ImagesGrid } from "polotno/side-panel/images-grid";
import { useCredits } from "../credits";
import { getCrop } from "polotno/utils/image";
import { useProject } from "../project";
import { getAPI } from "polotno/utils/api";
import { onSnapshot } from "mobx-state-tree";
import {
  ElementWithCustom,
  LearningStandardEntries,
  PageType,
  SelectEntries,
  SelectEntry,
  Topic,
} from "../types";
import { runInAction } from "mobx";
import { ancestorGenerator, fileToBase64 } from "../utils";
import { type StoreType } from "polotno/model/store";
import { custom } from "mobx-state-tree/dist/internal";
import { uploadDocument } from "../api";
import ArgSelectForm, { LabelledInput, type Args } from "../components/arg_form";
import { Select } from "@blueprintjs/select";
import {
  ArrowDown2,
  Cloud,
  DocumentUpload,
  ImportCurve,
  Link1,
  Magicpen,
  VideoSquare,
} from "iconsax-react";
import { AppToaster } from "../App";

import { LEGACY_TEMPLATES } from "../legacyTemplates"
import { nanoid } from "nanoid";

type PromptedImageResult = { url: string; prompt: string };

const PagePreview = ({ page, scale }: { page: PageType; scale: number }) => {
  let imRef = useRef<HTMLDivElement>(null);
  const [imgUrl, setImgUrl] = useState<string | null>(null);
  // const isActivePage = page.store.activePage === page;
  useEffect(() => {
    let timeout: number | null = null;
    const refresh = () => {
        if (timeout) {
          return;
        }
        timeout = window.setTimeout(() => {
          page.store
            .toDataURL({
              pageId: page.id,
              pixelRatio: 0.1,
            })
            .then(setImgUrl);
          timeout = null;
        }, 1e3);
      },
      disposeSnapshotListener = onSnapshot(page.children, refresh),
      intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((e) => {
            if (e.isIntersecting) {
              refresh();
            } else if (timeout) {
              window.clearTimeout(timeout);
            }
          });
        },
        { threshold: 0.1 }
      );
    return (
      imRef.current && intersectionObserver.observe(imRef.current),
      () => {
        intersectionObserver.disconnect(),
          timeout && window.clearTimeout(timeout),
          disposeSnapshotListener();
      }
    );
  }, []);
  return (
    <div
      ref={imRef}
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "15px",
        backgroundImage: `url("${imgUrl}")`,
        backgroundRepeat: "repeat-x",
        backgroundSize: "auto 100%",
        backgroundColor: "white",
      }}
      onClick={() => {
        page.store.selectPage(page.id);
      }}
    ></div>
  );
};

const topicIdMap = {
  "topic-text": "text",
  "topic-document": "document",
  "topic-video": "video",
  "topic-url": "url",
} as const;

const reverseTopicIdMap = {
  text: "topic-text",
  document: "topic-document",
  video: "topic-video",
  url: "topic-url",
} as const;

const topicSourceEntries = [
  { value: "text", label: "Text" },
  { value: "document", label: "Document" },
  { value: "video", label: "Video" },
  { value: "url", label: "URL" },
] as const satisfies SelectEntries;

const topicIconProps = {
  variant: "Linear",
  size: 20,
  className: "text-primary",
} as const;

const topicIconMap = {
  text: <ImportCurve {...topicIconProps} />,
  document: <DocumentUpload {...topicIconProps} />,
  video: <VideoSquare {...topicIconProps} />,
  url: <Link1 {...topicIconProps} />,
};

const TopicInput = observer(
  ({
    topic,
    setTopic,
    args,
    setArgs,
  }: {
    topic: Topic;
    setTopic: (topic: Topic) => void;
    args: Args;
    setArgs: React.Dispatch<(prevState: Args) => Args>;
  }) => {
    const { val } = topic;

    const [oldVals, setOldVals] = useState({
      text: "",
      document: "",
      video: "",
      url: "",
    });

    const updateTopic = (topic: Topic) => {
      setOldVals((oldVals) => ({
        ...oldVals,
        [topic.type]: topic.val,
      }));

      setTopic(topic);
    };

    return (
      <>
        <FormGroup
          label="Source"
          labelFor="source"
          className="font-semibold text-white"
        >
          <Select
            inputProps={{ id: "source" }}
            items={topicSourceEntries}
            itemRenderer={({ value, label }, { handleClick }) => (
              <MenuItem key={value} onClick={handleClick} text={label} />
            )}
            // noResults={<MenuItem disabled text="No results." />}
            filterable={false}
            activeItem={topicSourceEntries.find(
              (entry) => entry.value === topic.type
            )}
            onItemSelect={({ value }) => {
              updateTopic({
                type: value,
                val: oldVals[value] || "",
              });
            }}
            popoverProps={{ minimal: true, matchTargetWidth: true }}
          >
            <Button
              text={
                topicSourceEntries.find((entry) => entry.value === topic.type)
                  ?.label
              }
              // rightIcon="double-caret-vertical"
              // rightIcon="caret-down"
              // rightIcon="arrow-down"
              rightIcon={<ArrowDown2 {...topicIconProps} />}
              className="inline-flex rounded-lg bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-none w-full"
              textClassName="min-w-24 flex-1"
              icon={topicIconMap[topic.type]}
            />
          </Select>
        </FormGroup>

        <Tabs
          id=""
          selectedTabId={reverseTopicIdMap[topic.type]}
          onChange={(newTabId) => {
            const newType = topicIdMap[newTabId as keyof typeof topicIdMap];
            updateTopic({
              type: newType,
              val: oldVals[newType] || "",
            });
          }}
          className="[&_.bp5-tab-list]:hidden [&_.bp5-tab-panel]:mt-0"
        >
          <Tab
            id="topic-text"
            title="Text"
            panel={
              <TextArea
                value={topic.type === "text" ? val : oldVals.text}
                placeholder="Start typing some text here..."
                onChange={(e) => {
                  updateTopic({
                    type: "text",
                    val: e.target.value,
                  });
                }}
                className="w-full"
              />
            }
          />
          <Tab
            id="topic-document"
            title="Document"
            panel={
              <>
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-32 rounded-lg cursor-pointer bg-white hover:bg-gray-100"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <Cloud
                      size={36}
                      className="p-2 border border-solid border-1 rounded-full mb-2 text-gray-400"
                    />
                    <p className="mb-2 text-sm text-blue-500">
                      <span className="font-semibold">Click to upload</span>
                      {/* or drag and drop */}
                    </p>
                    <p className="text-xs text-gray-400">
                      PDF or DOCX (max 4MB)
                    </p>
                  </div>
                </label>
                <FileInput
                  title="Persisting these files are not supported yet. Please re-upload the file if you want to save it."
                  inputProps={{
                    id: "dropzone-file",
                    accept: ".pdf,.docx",
                    className: "hidden",
                  }}
                  onInputChange={async (e) => {
                    const file = (e.target as HTMLInputElement).files?.[0];
                    if (!file) {
                      return;
                    }
                    const data = file
                      ? (await uploadDocument({ file })).url
                      : "";
                    updateTopic({
                      type: "document",
                      val: data,
                      name: file.name,
                    });
                  }}
                  className="w-full hidden"
                />
                {val ? (
                  <div>
                    <p>
                      Currently selected:{" "}
                      <a href={topic.val} target="_blank">
                        {(topic.type === "document" && topic.name) ||
                          "[unnamed file]"}
                      </a>
                    </p>
                    <Button
                      onClick={() => {
                        updateTopic({
                          type: "document",
                          val: "",
                          name: "",
                        });
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </>
            }
          />
          <Tab
            id="topic-video"
            title="Video"
            panel={
              <InputGroup
                type="url"
                value={topic.type === "video" ? val : oldVals.video}
                placeholder={"Enter a YouTube video URL..."}
                onChange={(e) => {
                  updateTopic({
                    type: "video",
                    val: e.target.value,
                  });
                }}
                className="w-full"
              />
            }
          />
          <Tab
            id="topic-url"
            title="URL"
            panel={
              <InputGroup
                type="url"
                value={topic.type === "url" ? val : oldVals.url}
                placeholder={"Enter any URL..."}
                onChange={(e) => {
                  updateTopic({
                    type: "url",
                    val: e.target.value,
                  });
                }}
                className="w-full"
              />
            }
          />
        </Tabs>
        <ArgSelectForm state={args} setState={setArgs} />
      </>
    );
  }
);

const DEFAULT_TOPIC: Topic = {
  type: "text",
  val: "",
};

export const RegenSegment = observer(
  ({ store, children, selectedStandards }: { store: StoreType; children?: React.ReactNode; selectedStandards: LearningStandardEntries }) => {
    const project = useProject();
    const [refineLoading, setRefineLoading] = useState(false);

    const [createNewPage, setCreateNewPage] = useState(true);

    useEffect(() => {
      if (project.basicMode) {
        setCreateNewPage(false);
      } else {
        setCreateNewPage(true);
      }
    }, [project.basicMode]);

    const { activePage } = store;

    return (
      <>
      {/* <Tabs
        defaultSelectedTabId="refine-tab-new"
        className="[&_.bp5-tab-list]:justify-between [&_.bp5-tab]:text-white [&_.bp5-tab]:font-semibold [&_.bp5-tab-indicator]:bg-white pb-8"
      > */}
        {/* <Tab
          id="refine-tab-current"
          title="Current Page"
          panel={
            <>
              <TopicInput
                topic={activePage.custom?.topic || DEFAULT_TOPIC}
                setTopic={(topic) => {
                  runInAction(() => {
                    activePage.set({
                      custom: {
                        ...activePage.custom,
                        topic,
                      },
                    });
                  });
                }}
                args={activePage.custom?.args || {}}
                setArgs={(args) => {
                  runInAction(() => {
                    activePage.set({
                      custom: {
                        ...activePage.custom,
                        args: args(activePage.custom?.args || {}),
                      },
                    });
                  });
                }}
              />
              {children}
            </>
          }
        /> */}
        {/* <Tab
          id="refine-tab-new"
          title="New Page"
          panel={ */}
            <div className="flex flex-col">
              <TopicInput
                topic={project.newPageTopic || DEFAULT_TOPIC}
                setTopic={(topic) => {
                  runInAction(() => {
                    project.newPageTopic = topic;
                  });
                }}
                args={project.newPageArgs || {}}
                setArgs={(args) => {
                  runInAction(() => {
                    project.newPageArgs = args(project.newPageArgs || {});
                  });
                }}
              />
              {store.activePage?.custom?.template === "legacy_questions_answers" && (
                <div>
                  {([
                    ["# of True or False", "count_tf"],
                    ["# of Multiple Choice", "count_mc"],
                    ["# of Fill in the Blank", "count_fib"],
                    ["# of Short Answer", "count_sa"],
                    ["# of Essay", "count_essay"],
                  ] as const).map(([label, name]) => (
                    <LabelledInput
                      key={name}
                      id={name}
                      label={label}
                    >
                      <NumericInput
                        min={0}
                        max={25}
                        majorStepSize={5}
                        value={project.newPageArgs?.[name] ?? 5}
                        onValueChange={(val) => {
                          runInAction(() => {
                            project.newPageArgs = {
                              ...project.newPageArgs,
                              [name]: val,
                            };
                          });
                        }}
                      />
                    </LabelledInput>
                  ))}
                </div>
              )}
              {children}
              { store.activePage?.custom?.template
              && LEGACY_TEMPLATES.some(({value, enableSource}) => store.activePage.custom.template === value && enableSource)
              && (
                <Switch
                  className="text-white font-semibold mb-0 mt-2"
                  label="Use non-fiction sources (may take longer to generate)"
                  checked={!!project.newPageArgs?.source}
                  onChange={(e) => {
                    runInAction(() => {
                      project.newPageArgs = {
                        ...project.newPageArgs,
                        source: e.currentTarget.checked,
                      };
                    });
                  }}
                />
              ) }
              { !project.basicMode && (
              <Switch
                className="text-white font-semibold mb-0 mt-2"
                label={createNewPage ? "Create as new page" : "Replace page"}
                checked={createNewPage}
                onChange={(e) => {
                  setCreateNewPage(e.currentTarget.checked);
                }}
              />
              )}
              <Button
                className="mt-4"
                text={"Refine!"}
                textClassName="button-text-gradient"
                // intent="primary"
                type="button"
                loading={refineLoading}
                onClick={async () => {
                    if (!project.cloudEnabled && !project.demoMode) {
                      project.openLoginDialog();
                      return;
                    }

                    if (project.demoWorksheetsLeft <= 0) {
                      if (!project.cloudEnabled || !project.subscriptionActive) {
                        if (window !== window.parent && project.demoMode) {
                          project.openDemoDialog();
                          return;
                        }

                        AppToaster.then((toaster) =>
                          toaster.show({
                            intent: "warning",
                            message: "You have used your free remixes for this resource. Please log in/upgrade to continue refining.",
                          })
                        );

                    if (!project.cloudEnabled) {
                      project.openLoginDialog();
                      return;
                    }

                    if (!project.subscriptionActive) {
                      project.setPricingTableOpen(true);
                      return;
                    }
                        
                      }
                    }

                  const { activePage } = store;
                  if (!activePage) {
                    AppToaster.then((toaster) =>
                      toaster.show({
                        intent: "warning",
                        message: "Please select a page to refine.",
                      })
                    );
                    return;
                  }

                  if (!project.newPageTopic?.val) {
                    AppToaster.then((toaster) =>
                      toaster.show({
                        intent: "warning",
                        message: "Please select a topic for the new page.",
                      })
                    );
                    return;
                  }

                  setRefineLoading(true);
                  // const allJson = store.toJSON();
                  const activeGroupId = activePage.custom?.groupId;
                  const originalPages = (activeGroupId
                    ? store.pages.filter(({ custom }) => custom?.groupId === activeGroupId)
                    : [activePage]).map((page) => page.toJSON());
                  const originalPageIds = new Set(originalPages.map(({id}) => id));

                  originalPages[0].custom = originalPages[0].custom || {};

                  const { source } = project.newPageArgs || {};

                  const sourcesPageWidth = 595.2755905511812;
                  const sourcesPageHeight = 841.8897637795276;
                  const sourcesPageMargin = 25;
                  const sourcesPageTitleHeight = 20;
                  const sourcesPageTextWidth = sourcesPageWidth - sourcesPageMargin * 2;
                  const sourcesPageTextYFraction = 4 / 5;

                  if (source) {
                    originalPages[0].children.push({
                      id: "sources_title",
                      type: "text",
                      name: "{sources_title}",
                      text: "Sources",
                      align: "center",
                      fontSize: 25,
                      x: sourcesPageMargin,
                      y: sourcesPageMargin,
                      width: sourcesPageTextWidth,
                      moveToNewPage: true,
                    }, {
                      id: "sources_text",
                      type: "text",
                      name: "{sources[0].text}",
                      text: "",
                      align: "justify",
                      x: sourcesPageMargin,
                      y: sourcesPageTitleHeight + sourcesPageMargin * 2,
                      width: sourcesPageTextWidth,
                      moveToNewPage: true,
                    }, {
                      id: "sources_sources",
                      type: "text",
                      name: "{sources[0].sources[:]}",
                      text: "",
                      align: "justify",
                      x: sourcesPageMargin,
                      y: sourcesPageHeight * sourcesPageTextYFraction,
                      width: sourcesPageTextWidth,
                      moveToNewPage: true,
                    })
                  }

                  try {

                    const standards = selectedStandards.length > 0 ? selectedStandards.map(({description}) => description).join("\n") : undefined;

                  const newPages = await fetch("/api/backend/regen", {
                    method: "POST",
                    body: JSON.stringify({
                      pages: originalPages,
                      newTopic: project.newPageTopic,
                      newArgs: {
                        ...project.newPageArgs,
                        standards
                      },
                    }),
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }).then((res) => res.json());

                  if (source) {
                    const toMoveToNewPage = newPages[0].children
                      .filter(({ moveToNewPage }) => moveToNewPage)
                      .map(({ moveToNewPage, ...rest }) => rest);
                    const sourcesTitle = toMoveToNewPage.find(({ name }) => name === "{sources_title}");
                    const sourcesText = toMoveToNewPage.find(({ name }) => name === "{sources[0].text}");
                    const sourcesSources = toMoveToNewPage.find(({ name }) => name === "{sources[0].sources[:]}");
                    if (sourcesText && sourcesSources) {
                      sourcesSources.text = `<ol>${
                        sourcesSources.text
                          .split(/\n+/g)
                          .map((line) => `<li>${line}</li>`)
                          .join("\n")
                      }</ol>`;
                      newPages.push({
                        ...newPages[0],
                        width: sourcesPageWidth,
                        height: sourcesPageHeight,
                        id: nanoid(10),
                        children: [sourcesTitle, sourcesText, sourcesSources],
                      });
                    }
                    newPages[0].children = newPages[0].children.filter(({ moveToNewPage }) => !moveToNewPage);
                  }

                  const multiPageTexts: { id: string; text: string; pageId: string; }[] = [];

                  if (newPages[0].custom?.template === "legacy_questions_answers" || newPages[0].custom?.template === "story_questions") {
                    const names = new Set(["{answers[:]}", "{questions[:]}"]);
                    for (const page of newPages) {
                      for (const el of ancestorGenerator(page)) {
                        if (el.type === "text" && names.has(el.name)) {
                          multiPageTexts.push({
                            id: el.id,
                            text: el.text,
                            pageId: page.id,
                          })
                        }
                      }
                    }
                  }

                  async function findMaxLines(lines: string[], el, margin = 20) {
                      const {
                          y: y0,
                          fontSize,
                          lineHeight
                      } = el;
                      const pageHeight = store.pages[0].height === "auto" ? store.height : store.pages[0].height;

                      const heightTarget = pageHeight - margin - y0;

                      let lowestLineCount = 1;
                      let highestLineCount = Math.ceil(
                          (heightTarget / (lineHeight * fontSize)) * 1.666
                      ) + 1;

                      while (lowestLineCount < highestLineCount - 1) {
                          const lineCount = Math.floor((lowestLineCount + highestLineCount) / 2);
                          el.set({
                              text: lines.slice(0, lineCount).join("\n")
                          });
                          await store.waitLoading();
                          if (el.height < heightTarget) {
                              lowestLineCount = lineCount;
                          } else {
                              highestLineCount = lineCount;
                          }
                      }

                      el.set({
                          text: lines.slice(0, lowestLineCount).join("\n")
                      });

                      return lowestLineCount;
                  }


                  async function splitTextBox(firstPage, text: string, firstEl, margin = 20) {
                      const remainingLines = text.split("\n");
                      let page = firstPage
                      let el = firstEl;
                      let first = true;

                      while (remainingLines.length > 0) {
                          if (first) {
                              first = false;
                          } else {
                              const storeJSON = store.toJSON();
                              const newPageId = nanoid(10);
                              store.loadJSON({
                                  ...storeJSON,
                                  pages: storeJSON.pages.flatMap(
                                      (existingPage) => (
                                          existingPage.id === page.id ? [existingPage, {
                                              ...page,
                                              id: newPageId,
                                              children: [{
                                                  ...el.toJSON(),
                                                  id: nanoid(10),
                                                  y: margin,
                                                  text: remainingLines.join("\n"),
                                              }],
                                          }] : [existingPage]
                                      )
                                  ),
                              }, true);
                              store.selectPage(newPageId);
                              page = store.activePage;
                              el = page.children[0];
                          }
                          const lineCount = await findMaxLines(remainingLines, el, margin);
                          remainingLines.splice(0, lineCount);
                      }
                  }

                  project.runWithLoading(async () => {

                  await store.history.transaction(async () => {
                    const newAllJson = store.toJSON();
                    let allPages;
                    if (createNewPage) {
                      allPages = [...newAllJson.pages, ...newPages];
                    } else {
                      allPages = (newAllJson.pages as { id: string }[]).flatMap(
                        (page) => (
                          page.id === originalPages[0].id
                          ? newPages
                          : originalPageIds.has(page.id)
                            ? []
                            : [page]
                        )
                      );
                    }
                    store.loadJSON({ ...newAllJson, pages: allPages }, true);

                    for (const { id, text, pageId } of multiPageTexts) {
                      const el = store.getElementById(id);
                      const page = store.getElementById(pageId);
                      await splitTextBox(page, text, el);
                    }

                    store.selectPage(newPages[0].id);

                    project.requestSave();
                  });

                  if (!project.cloudEnabled || !project.subscriptionActive) {
                    project.useDemoWorksheet();
                  }

                  });

                  } catch (e) {
                    console.error(e);

                    AppToaster.then((toaster) =>
                      toaster.show({
                        intent: "danger",
                        message: "Something went wrong, please try again later...",
                      })
                    );

                  } finally {

                  setRefineLoading(false);

                  }
                }}
              />
              {
                activePage && !project.basicMode && (
                  <p className="py-4 text-white italic">
                    Page(s){" "}{
                      activePage.custom?.groupId ? store.pages
                        .map(({ custom }, i) => ({i, groupId: custom?.groupId}))
                        .filter(({groupId}) => groupId === activePage.custom?.groupId)
                        .map(({i}) => i + 1)
                        .join(", ")
                      : store.pages
                        .map(({id}, i) => ({i, id}))
                        .filter(({id}) => id === activePage.id)
                        .map(({i}) => i + 1)
                        .join(", ")
                    }{" "}will be refined.
                  </p>
                )
              }
            </div>
          {/* }
        />
      </Tabs> */}
      </>
    );
  }
);

const GenerateTab = observer(({ store }: { store: StoreType }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<PromptedImageResult | null>(null);
  const [loading, setLoading] = useState(false);

  const handleGenerate = async () => {
    if (!inputRef.current) {
      return;
    }
    const promptValue = inputRef.current.value;
    setLoading(true);
    setImage(null);
    const req = await fetch(
      `${getAPI()}/get-stable-diffusion?KEY=${getKey()}&prompt=${promptValue}`
    );
    setLoading(false);
    if (!req.ok) {
      alert("Something went wrong, please try again later...");
      return;
    }
    // consumeCredits();
    const data = await req.json();
    setImage({ url: data.output[0] as string, prompt: promptValue });
  };

  return (
    <>
      {/* <h3 className="text-lg">
        <Clean /> Refine with AI <Clean />
      </h3>

      <p>Make resources 10x quicker!</p>

      <RegenSegment store={store} />

      <Divider /> */}

      <div style={{ height: "40px", paddingTop: "5px" }}>
        Image Generation (beta)
      </div>
      <div style={{ padding: "15px 0" }}>
        Stable Diffusion is a latent text-to-image diffusion model capable of
        generating photo-realistic images given any text input
      </div>
      <InputGroup
        placeholder="Type your image generation prompt here..."
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleGenerate();
          }
        }}
        style={{
          marginBottom: "20px",
        }}
        inputRef={inputRef}
      />
      <Button
        onClick={handleGenerate}
        intent="primary"
        loading={loading}
        style={{ marginBottom: "40px" }}
      >
        Generate
      </Button>
      {image && (
        <ImagesGrid
          shadowEnabled={false}
          images={image ? [image] : []}
          getPreview={(item) => item.url}
          isLoading={loading}
          onSelect={async (item, pos, element) => {
            const { url: src, prompt } = item;
            if (element && element.type === "svg" && element.contentEditable) {
              element.set({ maskSrc: src });
              return;
            }

            if (
              element &&
              element.type === "image" &&
              element.contentEditable
            ) {
              element.set({ src: src });
              return;
            }

            const { width, height } = await getImageSize(src);
            const x = (pos?.x || store.width / 2) - width / 2;
            const y = (pos?.y || store.height / 2) - height / 2;
            store.activePage?.addElement({
              type: "image",
              src: src,
              width,
              height,
              x,
              y,
              custom: {
                prompt,
              },
            } as ElementWithCustom);
          }}
          rowsNumber={1}
        />
      )}
    </>
  );
});

const StableDiffusionPanel = observer(({ store }) => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      // className="bg-primary text-white"
    >
      <GenerateTab store={store} />
    </div>
  );
});

// define the new custom section
export const StableDiffusionSection = {
  name: "stable-diffusion",
  Tab: (props) => (
    <SectionTab name="AI Images" {...props}>
      <Magicpen variant="Linear" className="inline" />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: StableDiffusionPanel,
};
